.modal {
    &__header {
        display: flex;
        justify-content: space-between;
        &__close {
            cursor: pointer;
        }
    }
    &__title {
        font-size: 18px;
        font-weight: 700;
    }
}
.modalWrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 1000px);
    background: #fff;
    padding: 24px;
    border-radius: 12px;
    opacity: 0;
    transition: 0.3s all linear;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 0 5px 5px rgba(0,0,0,0.3);
    &__active {
        transform: translate(-50%, -50%);
        opacity: 1;
    }
}