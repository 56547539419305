@import './../../project/styles/variables-styles.module.scss';

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    &__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        cursor: pointer;
        margin: 0 5px;
        text-align: center;
        font-size: 12px;
        width: 30px;
        height: 30px;
        border-radius: 10px;
        border: 1px solid $mainColor;
        &:hover {
            background: $mainColor;
            color: white;
        }
    }
    &__chevron {
        font-size: 20px;
        cursor: pointer;
        &__btn {
            display: flex;
            justify-content: center;
            align-items: center;
            background: transparent;
            cursor: pointer;
            margin: 0 15px;
            border: none;
            width: 30px;
            height: 30px;
            &:hover {
                background: $mainColor;
                color: white;
                border-radius: 50%;
            }
        }
        &__btnOne {
            background: transparent;
            border: none;
        }
    }
  }

    .active {
      background: $mainColor;
      cursor: pointer;
      margin: 0 5px;
      text-align: center;
      font-size: 12px;
      width: 35px;
      height: 35px;
      border-radius: 10px;
      border: 1px solid ;
      color: white;
    }

 