@import "./../../project/styles/admin-global.module.scss";
$color: $mainAdminColor;
$headColor: $headAdminColor;
$colorHead: $colorHead;



.gridWrapper {
  width: fit-content;
  margin: 12px 0;
}
.gridBody {
  max-height: 95vh;
  overflow: auto;
}
.gridHead {
  display: flex;
  background-color: $headColor;
  border-radius: 5px 5px 0 0;
  // justify-content: space-around;
  padding: 15px 8px;
  width: 100%;
}
.gridHeadItem {
  text-align: center;
  width: 100%;
  font-weight: 700;
  color: $colorHead;
}
.gridRow {
  display: flex;
  align-items: center;
  // justify-content: space-around;
  padding: 8px 8px;
  border-bottom: 1px solid #ccc;
}
.gridRowSuccess {
background: green;
}
.gridRowCancel {
  background: yellow;
  }
  .gr
.gridRowItem {
  width: 100%;
  text-align: center ;
  display: flex;
  justify-content: center ;
}
.gridRowItem2 {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 10px;
}
.gridFilter {
  padding: 5px 10px;
  border-radius: 5px;
  border-color: $color;
  margin-bottom: 12px;
  font-size: 14px;
  cursor: pointer;
  &:hover,
  &:focus {
    border-color: $headColor;
    outline: none;
  }
}
.buttonWrapper {
  display: flex;
  justify-content: center;
  width: 150px;
  min-width: 150px;
}
.buttonWrapperItem {
  width: 30px;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  font-size: 14px;
  cursor: pointer;
  transition: 0.1s all linear;
  color: $headColor;
  &:hover {
    background: #ccc;
  }
}
//cells
.gridImage {
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 5px;
}
.gridImageItem {
  height: 100%;
}
.btn {
  background: $mainAdminColor;
  color: $colorBtnAdmin;
  outline: none;
  padding: 5px 10px;
  font-weight: 700;
  border: 1px solid $mainAdminColor;
  border-radius: 3px;
  margin-right: 5px;
  cursor: pointer;
  transition: 0.1s all linear;
  &:hover {
    color: $mainAdminColor;
    background: transparent;
  }
}
.title {
  font-size: 28px;
  margin-bottom: 12px;
}
