.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    &__input {
        margin: 3px 0;
        height: 30px;
        width: 200px;
    }
    &__btn {
       font-size: 20px;
       margin-top: 3px;
    }
    &__link {
       text-decoration: none;
    }
    &__icon {
        position: absolute;
        right: 3%;
        padding-top: 10px;
        cursor: pointer;
    }
    &__password {
        position: relative;
    }
}