@import './variables-styles.module.scss';

.gridHead {
    background: rgba(32, 104, 155, 1);
    font-weight: 700;  
    margin-top: 20px;  
}
.gridLink {
    background: $mainColor;
    padding: 5px 15px;
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
    border: 1px solid $mainColor;
    &:hover {
        color: $mainColor;
        border-color: $mainColor;
        background: #fff;
    }
}
.gridFilter {
    border-radius: 5px;
    border: 1px solid $mainColor;
    padding: 5px 15px;
    margin: 0 15px;
}
.createBtn {
    background: rgba(32, 104, 155, 1);
    width: 160px;
    height: 35px;
   
    cursor: pointer;
    color: white;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    border: 1px solid #292929 ;
    transition: 0.2s all linear;
    &:hover {
        background: #fdfbfb ;
        color:#292929 ;
       
    }

}