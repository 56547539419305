$fontFamily: 'Montserrat';
$colorSiteInput: gray;
$transition: 0.1s all linear;
$colorSiteInputHover: brown;
$colorError: red;

.inputWrapper {
    width: 100%;
    position: relative;
}
.input {    
    font-family: $fontFamily;
    outline: none;
    border: none;
    width: 460px;
    height: 50px;
    background: transparent !important;
    font-size: 22px;
    padding: 5px 10px 5px 50px;
    text-align: left;
    color: $colorSiteInput;
    border-bottom: 1px solid $colorSiteInput;
    margin-bottom: 16px;
    transition: $transition;
    &:hover {
        border-color: $colorSiteInputHover;
    }
    &:focus {
        border-color: $colorSiteInputHover;
    }
    &__phone {
        width: 100%;
        position: relative;
    }
}
.inputError {
    border-color: $colorError;
}
.fieldError {
    color: $colorError;
    margin-bottom: 10px;
    text-align: center;
}
.inputIcon {
    position: absolute;
    top: calc(50% - 4px);
    left: 10px;
    transform: translateY(-50%);
}
.svgInput {
    width: 22px;
    height: 22px;
    fill: $colorSiteInput;
    top: calc(50% - 2px);
}
.svgInputEmail {
    width: 22px;
    height: 22px;
    fill: $colorSiteInput;
    top: calc(50% - 2px);
}
.inputIconPassword {
    position: absolute;
    top: calc(50% - 8px);
    right: 15px;
    transform: translateY(-50%);
}
@media screen and (max-width: 1700px) {
    .input {    
        // border: 4px solid $colorMenu;
    }
}

@media screen and (max-width: 1366px) {
    .input {    
        // border: 3px solid $colorMenu;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 768px) {
    .input {    
        // border: 2px solid $colorMenu;
        font-size: 23px;
    }
}

@media screen and (max-width: 576px) {
    .input {
        width: 100%;
        font-size: 18px;
        padding: 12px 5px 12px 45px;
        margin-bottom: 6px;
    }
    .svgInput, .svgInputEmail {
        width: 20px;
        height: 20px;
    }
    .inputIconPassword {
        transform: translateY(-30%);
    }
    .inputIcon {
        top: 50%;
    }
}