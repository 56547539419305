.loader {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: fixed;
    right: -10%;
    top: 0;
    &__text {
    position: fixed;
    right: 40%;
    top: 40%;
    }
}

.loader__inner {
    bottom: 0;
    height: 60px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100px;
}

.loader__line__wrap {
    animation: 
		spin 2000ms cubic-bezier(.175, .885, .32, 1.275) infinite
	;
    box-sizing: border-box;
    height: 50px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    transform-origin: 50% 100%;
    width: 100px;
}
.loader__line {
    border: 4px solid transparent;
    border-radius: 100%;
    box-sizing: border-box;
    height: 100px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100px;
}
.loader__line__wrap:nth-child(1) { animation-delay: -50ms; }
.loader__line__wrap:nth-child(2) { animation-delay: -100ms; }
.loader__line__wrap:nth-child(3) { animation-delay: -150ms; }


.loader__line__wrap:nth-child(1) .loader__line {
    border-color: hsl(175, 89%, 44%);
    height: 90px;
    width: 90px;
    top: 7px;
}
.loader__line__wrap:nth-child(2) .loader__line {
    border-color: hsl(261, 88%, 52%);
    height: 76px;
    width: 76px;
    top: 14px;
}
.loader__line__wrap:nth-child(3) .loader__line {
    border-color: hsl(12, 85%, 54%);
    height: 62px;
    width: 62px;
    top: 21px;
}


@keyframes spin {
    0%, 15% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}